import React, { useState } from 'react'
import Layout from '../components/Layout'
import {
    Form,
    Input,
    Select,
    Radio,
    FormConsumer,
} from '../components/form/form'
import CAProvinces from '../data/geo/states-provinces/CA'
import USStates from '../data/geo/states-provinces/US'
import ReCAPTCHA from 'react-google-recaptcha'
// import {graphql} from "@hs_api";
import { graphql } from '../../../homespotter/intranet_tools/api/'
import { setAPIToken } from '../../../homespotter/intranet_tools/api/graphql'
import { API_TOKEN } from '../constants/globalConstants.js'

const CCPARequestForm = () => {
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [captchaValue, setCaptchaValue] = useState(false)
    const [showCaptcha, setShowCaptcha] = useState(false)

    setTimeout(() => {
        setShowCaptcha(true)
    }, 1000)

    // TBD
    const submit = (form) => {
        if (!captchaValue) {
            return false
        }

        const query = `
            mutation CreateCCPAComplianceRequest($input: CcpaComplianceRequestInput!) {
                createCcpaComplianceRequest(ccpaComplianceRequest: $input) {
                    productUserType
                    accountType
                    requestType
                    firstName
                    lastName
                    emailAddress
                    phoneNumber
                    country
                    stateAbbr
                    zip
                }
            }`

        let variables = {
            input: {
                productUserType: form['product_user_type'],
                accountType: form['account_type'],
                requestType: form['request_type'],
                firstName: form['first_name'],
                lastName: form['last_name'],
                emailAddress: form['email_address'],
                phoneNumber: form['phone_number'],
                country: form['country'],
                stateAbbr: form['state_abbr'],
                zip: form['zip'],
            },
        }

        setAPIToken(API_TOKEN)

        let retry = false

        graphql({ query, variables, retry }).then((json) => {
            if (json.errors) {
                setShowError(true)
                setShowSuccess(false)
            } else {
                setShowError(false)
                setShowSuccess(true)
            }
        })
    }

    // Return JSON for states/provinces depending on country selection
    const getStateOptions = (countryCode) => {
        if (countryCode === 'US') {
            stateOptions = USStates
        } else if (countryCode === 'CA') {
            stateOptions = CAProvinces
        } else {
            stateOptions = []
        }

        return stateOptions
    }

    const onCaptchaChange = (value) => {
        setCaptchaValue(value)
    }

    let stateOptions = []

    return (
        <Layout
            title="HomeSpotter Privacy Rights Request Form | HomeSpotter"
            description=""
        >
            <div className="ccpa-request">
                <h2>HomeSpotter Privacy Rights Request Form</h2>
                <p>
                    <strong>
                        Welcome to the HomeSpotter Privacy Rights Request Form
                    </strong>
                </p>
                <Form
                    className={'ccpa-request__form'}
                    onSubmit={submit}
                    initialValues={{
                        country: 'US',
                    }}
                >
                    <FormConsumer>
                        {({ formState }) => (
                            <div className={'ccpa-request__form-wrap'}>
                                {/* product_user_type */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--radio">
                                    <div className="ccpa-request__label-wrap">
                                        Are you a user of a HomeSpotter product
                                        (including Boost by HomeSpotter, Spacio
                                        by HomeSpotter, or any of the mobile
                                        apps powered by HomeSpotter) or an
                                        authorized representative of a user?
                                    </div>

                                    <div className="ccpa-request__input-wrap ccpa-request__input-wrap--radio">
                                        <Radio
                                            className={'ccpa-request__radio'}
                                            required
                                            id={'product_user_type__user'}
                                            name="product_user_type"
                                            label={'User'}
                                            value={'USER'}
                                        />

                                        <Radio
                                            className={'ccpa-request__radio'}
                                            required
                                            id={
                                                'product_user_type__authorized_rep'
                                            }
                                            name="product_user_type"
                                            label={'Authorized representative'}
                                            value={'AUTHORIZED_REPRESENTATIVE'}
                                        />
                                    </div>
                                </div>

                                {/* account_type */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--radio">
                                    <div className="ccpa-request__label-wrap">
                                        I am a:
                                    </div>

                                    <div className="ccpa-request__input-wrap ccpa-request__input-wrap--radio">
                                        <Radio
                                            className={'ccpa-request__radio'}
                                            required
                                            id={'account_type__consumer'}
                                            name="account_type"
                                            label={'Consumer'}
                                            value={'CONSUMER'}
                                        />

                                        <Radio
                                            className={'ccpa-request__radio'}
                                            required
                                            id={'account_type__agent'}
                                            name="account_type"
                                            label={'Real Estate Agent'}
                                            value={'AGENT'}
                                        />
                                    </div>
                                </div>

                                {/* request_type */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--radio">
                                    <div className="ccpa-request__label-wrap">
                                        Select request type:
                                    </div>
                                    <div className="ccpa-request__input-wrap ccpa-request__input-wrap--radio">
                                        <Radio
                                            className={'ccpa-request__radio'}
                                            required
                                            id={'request_type__personal_info'}
                                            name="request_type"
                                            label={
                                                'Access a copy of my personal information'
                                            }
                                            value={'PERSONAL_INFO'}
                                        />

                                        <Radio
                                            className={'ccpa-request__radio'}
                                            required
                                            id={'request_type__delete'}
                                            name="request_type"
                                            label={'Delete my information'}
                                            value={'DELETE'}
                                        />
                                    </div>
                                </div>

                                {/* first_name */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--text">
                                    <div className="ccpa-request__label-wrap">
                                        First Name
                                    </div>

                                    <Input
                                        name="first_name"
                                        maxLength={100}
                                        required
                                        id={'first_name'}
                                        placeholder="First Name"
                                    />
                                </div>

                                {/* last_name */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--text">
                                    <div className="ccpa-request__label-wrap">
                                        Last Name
                                    </div>

                                    <Input
                                        name="last_name"
                                        maxLength={100}
                                        required
                                        id={'first_name'}
                                        placeholder="Last Name"
                                    />
                                </div>

                                {/* email_address */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--text">
                                    <div className="ccpa-request__label-wrap">
                                        Email
                                    </div>

                                    <Input
                                        name="email_address"
                                        maxLength={254}
                                        required
                                        type={'email'}
                                        id={'email_address'}
                                        placeholder="Email"
                                    />
                                </div>

                                {/* phone_number */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--text">
                                    <div className="ccpa-request__label-wrap">
                                        Phone Number
                                    </div>

                                    <Input
                                        name="phone_number"
                                        maxLength={254}
                                        required
                                        type={'phone'}
                                        id={'phone_number'}
                                        placeholder="Phone Number"
                                    />
                                </div>

                                {/* country */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--select">
                                    <div className="ccpa-request__label-wrap">
                                        Country
                                    </div>

                                    <Select
                                        name="country"
                                        maxLength={254}
                                        required
                                        options={[
                                            {
                                                value: 'US',
                                                label: 'United States of America',
                                            },
                                            {
                                                value: 'CA',
                                                label: 'Canada',
                                            },
                                            {
                                                value: 'OT',
                                                label: 'Other',
                                            },
                                        ]}
                                        id={'country'}
                                        placeholder="Country"
                                    />
                                </div>

                                {/* state */}
                                <div
                                    style={{
                                        display:
                                            getStateOptions(
                                                formState['country']
                                            ).length > 0
                                                ? 'block'
                                                : 'none',
                                    }}
                                    className="ccpa-request__field-wrap ccpa-request__field-wrap--select"
                                >
                                    <div className="ccpa-request__label-wrap">
                                        {formState['country'] === 'US'
                                            ? 'State'
                                            : 'Province'}
                                    </div>

                                    <Select
                                        name="state_abbr"
                                        maxLength={254}
                                        required={
                                            getStateOptions(
                                                formState['country']
                                            ).length > 0
                                        }
                                        options={getStateOptions(
                                            formState['country']
                                        )}
                                        id={'state_abbr'}
                                        placeholder="State"
                                    />
                                </div>

                                {/* zip */}
                                <div className="ccpa-request__field-wrap ccpa-request__field-wrap--text">
                                    <div className="ccpa-request__label-wrap">
                                        Zip
                                    </div>

                                    <Input
                                        name="zip"
                                        maxLength={10}
                                        required
                                        type={'text'}
                                        id={'zip'}
                                        placeholder="Zip"
                                    />
                                </div>

                                {showCaptcha && (
                                    <ReCAPTCHA
                                        required="required"
                                        sitekey="6Lc6zQEVAAAAALu9iw6YQ8EV_KHiKx5ldbmjYtYx"
                                        onChange={onCaptchaChange}
                                    />
                                )}

                                {!showSuccess && (
                                    <div className="ccpa-request__field-wrap ccpa-request__field-wrap--submit">
                                        <button className="ccpa-reques__submit-button button button-cta progress">
                                            Submit
                                        </button>
                                    </div>
                                )}
                                {showError && (
                                    <p>
                                        {' '}
                                        There was an error submitting your form,
                                        please fix any errors and try again.
                                    </p>
                                )}
                                {showSuccess && (
                                    <p>Form submitted successfully!</p>
                                )}
                            </div>
                        )}
                    </FormConsumer>
                </Form>
            </div>
        </Layout>
    )
}

export default CCPARequestForm
